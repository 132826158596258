import { graphql, useStaticQuery } from 'gatsby';

type SanityNavItem = {
  id: string;
  footerOnly: boolean | null;
  label: string;
  route: {
    current: string;
  };
};

type SanityNavItems = Array<SanityNavItem>;

type SanityNavigation = { navItems: SanityNavItems };

type SanityNavigationResult = { sanityNavigation: SanityNavigation };

function useSanityNavigation(): SanityNavItems {
  const { sanityNavigation } = useStaticQuery<SanityNavigationResult>(graphql`
    query SanitySiteNavigationQuery {
      sanityNavigation {
        navItems {
          id: _key
          footerOnly
          label
          route {
            current
          }
        }
      }
    }
  `);
  return sanityNavigation.navItems;
}

export { useSanityNavigation };
export type {
  SanityNavigation,
  SanityNavigationResult,
  SanityNavItem,
  SanityNavItems,
};
