import { ChatIcon, HeartIcon } from '@heroicons/react/solid';
import * as React from 'react';
import { useInView } from 'react-intersection-observer';

import { useFetchInstagramPosts } from '../hooks/use-fetch-instagram-posts';
import { useSanitySiteSettings } from '../hooks/use-sanity-site-settings';
import { BackgroundTexture } from './background-texture';
import { Spinner } from './spinner';

function InstagramWidget(): React.ReactElement {
  const [ref, inView] = useInView({
    rootMargin: '200px',
    triggerOnce: true,
  });

  const PHOTO_COUNT = 6;

  const config = useSanitySiteSettings();

  const instagram = config.socialLinks.find(
    ({ socialNetwork }) => socialNetwork === 'Instagram'
  );

  return (
    <div ref={ref} className="relative block w-full">
      <BackgroundTexture />
      <div className="relative mx-auto space-y-5 py-14 md:py-32 max-w-screen-2xl sm:px-6 lg:px-8">
        {instagram ? (
          <div className="flex flex-col items-center justify-center space-x-2 text-center">
            <h2 className="text-lg font-semibold md:text-4xl">
              <a
                href={'https://www.instagram.com/adorebeauty_cosmetictattoo/'}
                target="_blank"
                className="z-10 transition duration-150 ease-in-out text-custom-gradient"
              >
                @adorebeauty_cosmetictattoo
              </a>
            </h2>
            <span className="md:text-2xl">Follow us on Instagram</span>
          </div>
        ) : null}
        <div className="flex flex-wrap justify-center gap-8 pt-12">
          <iframe
            width="320"
            height="370"
            scrolling="no"
            src="https://www.instagram.com/p/C4rhyLEpkrL/embed"
            frameborder="0"
          ></iframe>
          <iframe
            width="320"
            height="370"
            scrolling="no"
            src="https://www.instagram.com/p/C4hoLAtpZkp/embed"
            frameborder="0"
          ></iframe>
          <iframe
            width="320"
            height="370"
            scrolling="no"
            src="https://www.instagram.com/p/C4cDvIgpKvd/embed"
            frameborder="0"
          ></iframe>
          <iframe
            width="320"
            height="370"
            scrolling="no"
            src="https://www.instagram.com/p/C4MS0ZXB5UQ/embed"
            frameborder="0"
          ></iframe>
        </div>

        {/* <div className="w-full p-4 mx-auto">
          <ul className="grid grid-cols-3 gap-4 sm:grid-cols-3 md:grid-cols-6">
            {inView ? (
              <InstagramPosts photoCount={PHOTO_COUNT} />
            ) : (
              <SkeletonScreen photoCount={PHOTO_COUNT} />
            )}
          </ul>
        </div> */}
      </div>
    </div>
  );
}

interface IPost {
  photoCount: number;
}

function SkeletonScreen({ photoCount }: IPost): React.ReactElement {
  return (
    <>
      {new Array(photoCount).fill('').map((_, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <li key={i} className="relative bg-gray-100 aspect-w-1 aspect-h-1">
          <div className="absolute inset-0 flex items-center justify-center w-full h-full bg-opacity-50 bg-pink-lightest">
            <Spinner />
          </div>
        </li>
      ))}
    </>
  );
}

function InstagramPosts({ photoCount }: IPost): React.ReactElement {
  const posts = useFetchInstagramPosts(
    photoCount,
    process.env.GATSBY_INSTAGRAM_ID as string
  );

  if (posts.length === 0 || !Array.isArray(posts)) {
    return <SkeletonScreen photoCount={photoCount} />;
  }

  return (
    <>
      {posts.map(
        (
          { caption, comments, height, id, likes, thumbnail_src, url, width },
          index
        ) =>
          index < 6 && (
            <a
              key={id}
              href={url}
              target="_blank"
              rel="noopener noreferrer"
              className="relative aspect-w-1 aspect-h-1"
            >
              <img
                src={`https://hidden-springs-13326.herokuapp.com/imgproxy.php?url=${encodeURIComponent(
                  thumbnail_src
                )}`}
                alt={caption?.slice(0, 40)}
                height={height}
                width={width}
                className="absolute inset-0 object-contain w-full h-full"
              />
              <div className="flex items-center justify-center text-white transition duration-150 ease-in-out bg-opacity-50 opacity-0 hover:bg-custom-gradient hover:opacity-100">
                <dl className="flex space-x-8">
                  <div className="flex items-center space-x-1">
                    <dt>
                      <span className="sr-only">Link</span>
                      <HeartIcon
                        aria-hidden
                        focusable={false}
                        className="w-4 h-4"
                      />
                    </dt>
                    <dd>{likes ? likes.toLocaleString() : 0}</dd>
                  </div>
                  <div className="flex items-center space-x-1">
                    <dt>
                      <span className="sr-only">Comments</span>
                      <ChatIcon
                        aria-hidden
                        focusable={false}
                        className="w-4 h-4"
                      />
                    </dt>
                    <dd>{comments ? comments.toLocaleString() : 0}</dd>
                  </div>
                </dl>
              </div>
            </a>
          )
      )}
    </>
  );
}

export { InstagramWidget };
