import { StaticImage } from 'gatsby-plugin-image';
import * as React from 'react';

type BackgroundTextureProps = {
  position?: 'left' | 'right' | 'center';
};

function BackgroundTexture({
  position = 'right',
}: BackgroundTextureProps): React.ReactElement {
  return (
    <div
      aria-hidden
      className={`absolute inset-0 flex transform z-0 ${
        position === 'left'
          ? '-translate-x-1/3'
          : position === 'center'
          ? ''
          : 'translate-x-1/3'
      }`}
    >
      <div className="flex ">
        <StaticImage
          quality={90}
          src="../images/bg-texture.png"
          placeholder="none"
          alt=""
          objectFit="contain"
          className="flex-1 w-full"
        />
      </div>
    </div>
  );
}

export { BackgroundTexture };
