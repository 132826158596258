import * as React from 'react';

function InstagramIcon(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): React.ReactElement {
  return (
    <svg fill="currentColor" viewBox="0 0 18 18" {...props}>
      <path d="M9.008 1.622c2.405 0 2.69.01 3.64.052 1.025.047 1.977.252 2.709.983.731.731.937 1.681.984 2.706.043.95.052 1.234.052 3.637s-.009 2.688-.052 3.637c-.047 1.025-.252 1.975-.985 2.706-.731.73-1.683.936-2.708.983-.95.043-1.234.052-3.64.052-2.405 0-2.69-.01-3.64-.052-1.025-.047-1.976-.252-2.708-.983-.732-.731-.937-1.681-.985-2.706-.043-.95-.052-1.234-.052-3.637s.01-2.688.053-3.637c.047-1.025.252-1.975.984-2.706.732-.731 1.683-.936 2.708-.983.95-.043 1.235-.052 3.64-.052zm0-1.622C6.562 0 6.255.01 5.294.054 3.83.121 2.544.48 1.512 1.51.48 2.542.12 3.825.054 5.289.01 6.249 0 6.556 0 9s.01 2.75.054 3.71c.067 1.463.426 2.748 1.458 3.78 1.032 1.03 2.318 1.388 3.782 1.456.961.043 1.268.054 3.714.054 2.447 0 2.753-.01 3.714-.054 1.464-.068 2.75-.425 3.782-1.457 1.032-1.03 1.391-2.315 1.458-3.779.044-.96.054-1.266.054-3.71s-.01-2.751-.054-3.711c-.067-1.462-.426-2.747-1.458-3.78C15.472.48 14.186.122 12.722.055 11.762.01 11.455 0 9.008 0z" />
      <path d="M9.008 4.379a4.623 4.623 0 00-4.626 4.62 4.624 4.624 0 004.626 4.622A4.624 4.624 0 0013.634 9a4.623 4.623 0 00-4.626-4.621zm0 7.62A3.002 3.002 0 016.005 9a3.002 3.002 0 016.006-.001c0 1.657-1.345 3-3.003 3zm4.809-6.724a1.08 1.08 0 100-2.16 1.08 1.08 0 10-.001 2.16h.001z" />
    </svg>
  );
}

export { InstagramIcon };
