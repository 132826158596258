import * as React from 'react';
import { Footer } from './footer';

import { Nav } from './nav';
import { Topbar } from './topbar';

interface LayoutProps {
  children: React.ReactNode;
}

function Layout({ children }: LayoutProps): React.ReactElement {
  return (
    <div className="max-w-[1920px] w-full mx-auto">
      <Topbar />
      <Nav />
      <div className="relative flex flex-col overflow-x-hidden font-sans antialiased bg-white text-gray fill-available ">
        <main className="relative flex flex-col flex-1">{children}</main>
      </div>
      <Footer />
    </div>
  );
}

export { Layout };
