import { Link } from 'gatsby';
import * as React from 'react';

import { useSanitySiteSettings } from '../hooks/use-sanity-site-settings';
import { SOCIAL_MAP } from '../lib/social-map';

function Topbar(): React.ReactElement {
  const config = useSanitySiteSettings();
  return (
    <header>
      <div className="md:hidden bg-pink text-white text-center">
        <Link
          to="/cosmetic-tattooing/#consultation-form"
          className="text-lg underline py-3 block"
        >
          Enquire About Cosmetic Tattooing
        </Link>
      </div>
      <div className="hidden md:block bg-pink-lightest text-pink-dark">
        <div className="flex items-center justify-between w-full px-4 py-4 mx-auto md:space-x-8 md:flex lg:space-x-12 max-w-screen-2xl sm:px-6 lg:px-8">
          <div className="flex space-x-8">
            <div className="flex items-center space-x-4">
              {config.socialLinks.map(({ id, link, socialNetwork }) => {
                const Icon = SOCIAL_MAP[socialNetwork];
                return (
                  <a key={id} href={link}>
                    <span className="sr-only">{socialNetwork}</span>
                    <Icon className="w-5 h-5" />
                  </a>
                );
              })}
            </div>
            <div>
              Call us on{' '}
              <a href={`tel:${config.phoneNumber}`} className="font-bold">
                {config.phoneNumber}
              </a>
            </div>
          </div>
          <Link
            to="/cosmetic-tattooing/#consultation-form"
            className="inline-flex px-4 py-2 font-semibold  text-white rounded-full bg-gradient-to-r from-pink-darker via-pink-light to-pink-dark undefined"
          >
            Enquire About Cosmetic Tattooing
          </Link>
        </div>
      </div>
    </header>
  );
}

export { Topbar };
