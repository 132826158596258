import * as React from 'react';

function FacebookIcon(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): React.ReactElement {
  return (
    <svg fill="currentColor" viewBox="0 0 18 18" {...props}>
      <path d="M16.5 0h-15A1.5 1.5 0 000 1.5v15A1.5 1.5 0 001.5 18h8.25v-6.75H7.5v-3h2.25V6.31c0-2.325 1.42-3.591 3.494-3.591.994 0 1.848.074 2.097.107v2.43h-1.439c-1.128 0-1.346.537-1.346 1.323V8.25h3.33l-.75 3h-2.58V18H16.5a1.5 1.5 0 001.5-1.5v-15A1.5 1.5 0 0016.5 0z" />
    </svg>
  );
}

export { FacebookIcon };
