import { Dialog, Transition } from '@headlessui/react';
import {
  CalendarIcon,
  ChartBarIcon,
  FolderIcon,
  HomeIcon,
  InboxIcon,
  MenuIcon,
  UsersIcon,
  XIcon,
} from '@heroicons/react/outline';
import { useLocation } from '@reach/router';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import * as React from 'react';

import {
  SanityNavItems,
  useSanityNavigation,
} from '../hooks/use-sanity-site-navigation';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function Nav(): React.ReactElement {
  // Navigation
  const sanityNavigation = useSanityNavigation();

  // Mobile menu
  const [isOpen, setIsOpen] = React.useState(false);
  const toggle = (): void => setIsOpen((prev) => !prev);

  return (
    <nav className="sticky top-0 z-30 w-full bg-white">
      <div className="flex items-center justify-between w-full px-4 py-2 mx-auto md:space-x-8 md:flex lg:space-x-12 max-w-screen-2xl text-pink-dark sm:px-6 lg:px-8">
        <Link to="/" className="flex-shrink-0 block">
          <StaticImage
            src="../images/logo.png"
            alt=""
            height={96}
            objectFit="contain"
            className="h-full"
          />
        </Link>
        <NavItems navItems={sanityNavigation} />
        <div className="md:hidden">
          <button
            type="button"
            onClick={toggle}
            className="inline-flex items-center justify-center p-2 transition duration-150 ease-in-out rounded-md text-pink hover:text-pink-dark hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-pink"
            aria-controls="mobile-menu"
            aria-expanded="false"
          >
            <span className="sr-only">Open main menu</span>
            <MenuIcon className="block w-6 h-6" />
          </button>
        </div>
      </div>
      <MobileMenu isOpen={isOpen} toggle={toggle} />
    </nav>
  );
}

type NavItemsProps = {
  navItems: SanityNavItems;
};

function NavItems({ navItems }: NavItemsProps): React.ReactElement {
  const { pathname } = useLocation();

  return (
    <div className="hidden space-x-4 xl:space-x-24 md:block text-xs font-medium whitespace-nowrap">
      <Link
        to={`/`}
        className={classNames('/' === pathname ? 'text-gray' : 'text-pink')}
      >
        Home
      </Link>
      {navItems.map(
        ({ id, route, label }) =>
          route?.current && (
            <Link
              key={id}
              to={`/${route.current}/`}
              className={classNames(
                `/${route.current}/` === pathname ? 'text-gray' : 'text-pink'
              )}
            >
              {label}
            </Link>
          )
      )}
    </div>
  );
}

const navigation = [
  { name: 'Dashboard', href: '#', icon: HomeIcon, current: true },
  { name: 'Team', href: '#', icon: UsersIcon, current: false },
  { name: 'Projects', href: '#', icon: FolderIcon, current: false },
  { name: 'Calendar', href: '#', icon: CalendarIcon, current: false },
  { name: 'Documents', href: '#', icon: InboxIcon, current: false },
  { name: 'Reports', href: '#', icon: ChartBarIcon, current: false },
];

type MobileMenuProps = {
  isOpen: boolean;
  toggle: () => void;
};

function MobileMenu({ isOpen, toggle }: MobileMenuProps): React.ReactElement {
  const sanityNavigation = useSanityNavigation();
  const { pathname } = useLocation();
  // DialogContent, DialogOverlay
  return (
    <Transition.Root show={isOpen} as={React.Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 z-40 flex md:hidden"
        open={isOpen}
        onClose={toggle}
      >
        <Transition.Child
          as={React.Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </Transition.Child>
        <Transition.Child
          as={React.Fragment}
          enter="transition ease-in-out duration-300 transform"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
        >
          <div className="relative flex flex-col flex-1 w-full max-w-xs bg-white">
            <Transition.Child
              as={React.Fragment}
              enter="ease-in-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="absolute top-0 right-0 pt-2 -mr-12">
                <button
                  type="button"
                  className="flex items-center justify-center w-10 h-10 ml-1 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                  onClick={toggle}
                >
                  <span className="sr-only">Close sidebar</span>
                  <XIcon className="w-6 h-6 text-white" aria-hidden="true" />
                </button>
              </div>
            </Transition.Child>
            <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
              <div className="flex items-center flex-shrink-0 px-4">
                <Link to="/" className="inline-flex">
                  <StaticImage
                    src="../images/logo.png"
                    alt=""
                    height={96}
                    objectFit="contain"
                    className="h-full"
                  />
                </Link>
              </div>
              <nav className="px-2 mt-5 space-y-1">
                <Link
                  to={`/`}
                  className={classNames(
                    pathname === '/'
                      ? 'bg-gray-100 text-gray-900'
                      : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                    'group flex items-center px-2 py-2 text-base font-medium rounded-md'
                  )}
                >
                  Home
                </Link>
                {sanityNavigation.map(({ id, route, label }) => {
                  const isCurrent = pathname === `/${route.current}/`;
                  return (
                    <Link
                      key={id}
                      to={`/${route.current}/`}
                      className={classNames(
                        isCurrent
                          ? 'bg-gray-100 text-gray-900'
                          : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                        'group flex items-center px-2 py-2 text-base font-medium rounded-md'
                      )}
                    >
                      {label}
                    </Link>
                  );
                })}
              </nav>
            </div>
          </div>
        </Transition.Child>
        <div className="flex-shrink-0 w-14">
          {/* Force sidebar to shrink to fit close icon */}
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export { Nav };
