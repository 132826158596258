import { graphql, useStaticQuery } from 'gatsby';

type ShareImage = {
  asset: {
    url: string;
  };
};

type SocialLinks = Array<{
  id: string;
  link: string;
  socialNetwork: 'Facebook' | 'Instagram' | 'LinkedIn' | 'Twitter';
}>;

type SanitySiteSettings = {
  description: string;
  email: string;
  phoneNumber: string;
  shareImage: ShareImage;
  socialLinks: SocialLinks;
  siteUrl: string;
  title: string;
};

type SanitySiteSettingsResult = {
  sanitySiteSettings: SanitySiteSettings;
};

function useSanitySiteSettings(): SanitySiteSettings {
  const {
    sanitySiteSettings,
  } = useStaticQuery<SanitySiteSettingsResult>(graphql`
    {
      sanitySiteSettings(_id: { eq: "siteSettings" }) {
        description
        email
        phoneNumber
        shareImage {
          asset {
            url
          }
        }
        siteUrl
        socialLinks {
          id: _key
          link
          socialNetwork
        }
        title
      }
    }
  `);
  return sanitySiteSettings;
}

export { useSanitySiteSettings };
