import { Link } from 'gatsby';
import * as React from 'react';

import { useSanityNavigation } from '../hooks/use-sanity-site-navigation';
import { useSanitySiteSettings } from '../hooks/use-sanity-site-settings';
import { Logo } from '../icons/logo';
import { SOCIAL_MAP } from '../lib/social-map';

function Footer(): React.ReactElement {
  const navItems = useSanityNavigation();
  const config = useSanitySiteSettings();
  return (
    <footer className="bg-white" aria-labelledby="footerHeading">
      <h2 id="footerHeading" className="sr-only">
        Footer
      </h2>
      <div className="px-4 pt-12 mx-auto max-w-screen-2xl sm:px-6 lg:pt-16 lg:px-8 text-pink">
        <div className="grid gap-8 lg:grid-cols-2">
          <div className="flex items-start justify-center lg:justify-start">
            <Link to="/" className="block w-full max-w-xs">
              <Logo />
            </Link>
          </div>
          <div>
            <div className="grid gap-8 py-8 border-b border-solid border-pink md:grid-cols-5">
              <div className="md:col-span-2">
                <nav className="space-y-4 text-pink">
                  <Link to={`/`} className="block hover:underline">
                    Home
                  </Link>
                  {navItems.map(({ id, route, label }) => (
                    <Link
                      key={id}
                      to={route?.current ? `/${route.current}` : '/'}
                      className="block hover:underline"
                    >
                      {label}
                    </Link>
                  ))}
                </nav>
              </div>
              <div className="md:col-span-3">
                <dl className="space-y-4 text-sm xl:text-lg">
                  <div className="hidden md:block">
                    <Link
                      to="/cosmetic-tattooing/#consultation-form"
                      className="inline-flex px-4 py-2 font-semibold text-center text-white rounded-full bg-gradient-to-r from-pink-darker via-pink-light to-pink-dark undefined"
                    >
                      Enquire About Cosmetic Tattooing
                    </Link>
                  </div>
                  <div>
                    <dt className="inline mr-2 font-semibold">Phone </dt>
                    <dd className="inline">
                      <a
                        href={`tel:${config.phoneNumber}`}
                        className="hover:underline"
                      >
                        {config.phoneNumber}
                      </a>
                    </dd>
                  </div>
                  <div>
                    <dt className="inline mr-2 font-semibold">Email </dt>
                    <dd className="inline">
                      <a
                        href={`mailto:${config.email}`}
                        className="hover:underline"
                      >
                        {config.email}
                      </a>
                    </dd>
                  </div>
                </dl>
                <div className="flex items-center mt-4 space-x-4 text-sm xl:text-lg ">
                  <h3 className="mr-2 font-semibold">Follow us at</h3>
                  <div className="space-x-4">
                    {config.socialLinks.map(({ id, link, socialNetwork }) => {
                      const Icon = SOCIAL_MAP[socialNetwork];
                      return (
                        <a
                          key={id}
                          href={link}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="inline-block mt-1 transition duration-150 ease-in-out hover:opacity-75"
                        >
                          <span className="sr-only">{socialNetwork}</span>
                          <Icon aria-hidden className="w-6 h-6" />
                        </a>
                      );
                    })}
                  </div>
                </div>
                <div className="flex justify-center text-xs mt-9 md:hidden">
                  <Link
                    to="/cosmetic-tattooing/#consultation-form"
                    className="inline-flex px-3 py-2 font-semibold text-center text-white rounded-full bg-gradient-to-r from-pink-darker via-pink-light to-pink-dark undefined"
                  >
                    Enquire About Cosmetic Tattooing
                  </Link>
                </div>
              </div>
            </div>
            <p className="mt-2 mb-8 text-base font-medium leading-6 tracking-wider text-center lg:text-left">
              <a
                href="https://pd.design"
                target="_blank"
                rel="noopener noreferrer"
              >
                Designed and developed by <span className="font-bold">PD</span>
              </a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export { Footer };
